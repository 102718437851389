import {FC} from 'react'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type Props = {
  user?: any
  mapData: string
  number: number
}

const SerialNumber: FC<Props> = ({number}) => {
  const numbers = number + 1
  const {state} = useQueryRequest()
  const {page, size = 10} = state
  return <div className='d-flex flex-column'>{page === 0 ? number + 1 : page * size + numbers}</div>
}

export {SerialNumber}
