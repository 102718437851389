/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Dropdown1 } from '../../content/dropdown/Dropdown1';

type Props = {
  className: string;
  color: string;
  bg_color?: string;
  data?: any;
};

interface Customer {
  customerId: string;
  customerName: string;
  totalRewards: number;
}

interface TopCustomersResponse {
  data: Customer[];
  userPoint: number;
  streakMultiplier: number;
}

const MixedWidget1: React.FC<Props> = ({ data, className, color, bg_color }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<'thisMonth' | 'lifetime'>('lifetime'); // Default is lifetime
  const [topCustomers, setTopCustomers] = useState<TopCustomersResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const returnData = (amount: number | undefined) => {
    return (
      <div>
        <div className='fw-bold'>{amount?.toFixed() || '0'} kg</div>
      </div>
    );
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPeriod('lifetime');
  };

  const fetchTopCustomers = async (period: string) => {
    setLoading(true);
    setError(null);
    try {
      const url = period === 'thisMonth'
        ? `${process.env.REACT_APP_BASE_API_URL}pickup-point/${data.pickupPointId}/top-customers?thisMonth=true`
        : `${process.env.REACT_APP_BASE_API_URL}pickup-point/${data.pickupPointId}/top-customers`;

      const response = await fetch(url);
      const data2: TopCustomersResponse = await response.json();
      setTopCustomers(data2);
    } catch (err) {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleModalOpen = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsModalOpen(true);
    setSelectedPeriod('lifetime'); // Set selectedPeriod to 'lifetime' when modal opens
    fetchTopCustomers('lifetime'); // Fetch data for 'lifetime' when modal opens
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPeriod = event.target.value as 'thisMonth' | 'lifetime';
    setSelectedPeriod(newPeriod);
    if (isModalOpen) {
      fetchTopCustomers(newPeriod); // Fetch new data when period changes
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchTopCustomers(selectedPeriod);
    }
  }, [isModalOpen, selectedPeriod]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div
          className={`px-9 pt-7 card-rounded h-275px w-100`}
          style={{ backgroundColor: bg_color || '#E89611' }}
        >
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{data?.pickupPointName}</h3>
            <div className='ms-1'>
              <Dropdown1 />
            </div>
          </div>

          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'> Total Pick up</span>
            <span className='fw-bold fs-2x pt-1'>
              {returnData(data?.supplied)}
            </span>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{ marginTop: '-100px' }}
        >
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Collected
                </a>
              </div>
              {returnData(data?.collected)}
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Stock
                </a>
              </div>

              <div className='fw-bold'>
                {data?.stock?.toFixed(2) || '0'} kg
              </div>
            </div>
          </div>

          {/* Updated to button */}
          <div className='d-flex align-items-center mb-6'>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <button
                  className='btn btn-primary'
                  onClick={handleModalOpen}
                >
                  View Top 100
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}

      {/* Modal for Top 100 */}
      {isModalOpen && (
        <div className='modal fade show' style={{ display: 'block' }} tabIndex={-1} aria-labelledby='modalTop100' aria-hidden='true'>
          <div className='modal-dialog modal-dialog-centered'>
            <div className='modal-content'
              style={{
                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.2)', /* More intense shadow */
                borderRadius: '8px' /* Optional: Rounded corners for a smoother look */
              }}
            >
              <div className='modal-header'>
                <h5 className='modal-title' id='modalTop100'>{data?.pickupPointName}</h5>
                <button type='button' className='btn-close' onClick={handleModalClose}></button>
              </div>
              <div className='modal-body'>
                <div className='mb-4'>
                  <div>
                    <label
                      className='form-check-label me-3'
                      style={{ cursor: 'pointer' }} // Adding the pointer cursor for hover effect
                    >
                      <input
                        type='radio'
                        className='form-check-input'
                        name='period'
                        value='thisMonth'
                        checked={selectedPeriod === 'thisMonth'}
                        onChange={handleRadioChange}
                      />
                      &nbsp; This Month
                    </label>
                    <label
                      className='form-check-label'
                      style={{ cursor: 'pointer' }} // Adding the pointer cursor for hover effect
                    >
                      <input
                        type='radio'
                        className='form-check-input'
                        name='period'
                        value='lifetime'
                        checked={selectedPeriod === 'lifetime'}
                        onChange={handleRadioChange}
                      />
                      &nbsp; Lifetime
                    </label>
                  </div>
                </div>

                {/* Loading/Error Messages */}
                {loading && <div>Loading...</div>}
                {error && <div>{error}</div>}

                {/* Data Table */}
                {!loading && !error && (
                  <table className='table'>
                    <thead>
                      <tr>
                        <th style={{ fontWeight: 'bold' }}>SL</th>
                        <th style={{ fontWeight: 'bold' }}>Name</th>
                        <th style={{ fontWeight: 'bold' }}>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topCustomers?.data?.length === 0 ? (
                        <tr>
                          <td colSpan={3} style={{ textAlign: 'center' }}>No records found</td>
                        </tr>
                      ) : (
                        topCustomers?.data?.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.customerName}</td>
                            <td>{item.totalRewards}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-secondary' onClick={handleModalClose}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { MixedWidget1 };
