/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import {
  generateAddress,
  configCategoryMap,
  toAbsoluteUrl,
} from '../../../../../../../_metronic/helpers'
import { User } from '../../core/_models'

type Props = {
  user: any
  showImage?: boolean
  mapData?: string
  showImageOnly?: boolean
}

export const returnData = (user, mapData) => {
  const accessNestedProperty = (obj, path) => {
    return path.split('[').reduce((acc, key) => {
      return acc && acc[key.replace(']', '')]
    }, obj)
  }
  const configMapData = configCategoryMap

  const ratingStart = (number: number) => {



    return (
      <div className='rating'>
        <div className={`rating-label me-2 ${number >= 1 ? 'checked' : ''}`}>
          <i className='bi bi-star-fill fs-5'></i>
        </div>
        <div className={`rating-label me-2 ${number >= 2 ? 'checked' : ''}`}>
          <i className='bi bi-star-fill fs-5'></i>
        </div>
        <div className={`rating-label me-2 ${number >= 3 ? 'checked' : ''}`}>
          <i className='bi bi-star-fill fs-5'></i>
        </div>
        <div className={`rating-label me-2 ${number >= 4 ? 'checked' : ''}`}>
          <i className='bi bi-star-fill fs-5'></i>
        </div>
        <div className={`rating-label me-2 ${number >= 5 ? 'checked' : ''}`}>
          <i className='bi bi-star-fill fs-5'></i>
        </div>
      </div>
    )
  }

  if (mapData?.includes('[')) return accessNestedProperty(user, mapData)
  switch (mapData) {
    case 'qualityRating':
      return <div className='me-6'>{ratingStart(user[mapData])}</div>
    case 'facilityType':
      return user?.facilityType?.toUpperCase()
    case 'categoryMap':
      return <div>{configMapData[user.categoryId] || ''}</div>
    case 'format':
      return user.companyDetails.format || 'OWN'
    case 'contactDetail':
      return (
        <div>
          <div>{user.personalDetails.mobile}</div>
          <div>{user.personalDetails.email}</div>
        </div>
      )
    case 'profileImage':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={`${user?.personalDetails?.profileImage || ''}`}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    case 'categoryIcon':
      return (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={`${user?.orderDetails?.[0]?.categoryIcon || ''}`}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )
    case 'categoryName':
      return user?.orderDetails?.[0]?.categoryName || user?.categoryName || ''
    case 'quantityDeposit':
      const totalQuantity =
        user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) || 0
      return <span className='text-primary fs-7 fw-bold'>{totalQuantity} kg</span>
    case 'pickup_quantity_item':
      return user?.orderDetails?.[0].items?.map((x: any, ind: number) => (
        <span className={`badge badge-light-info fs-7 fw-bold`} key={ind + 1 + ''}>
          {mapData === 'pickup_quantity_item' ? x.itemName : x.quantity}
        </span>
      ))
    case 'plasticStation':
      return user?.pickupPoints?.map((x: any, ind: number) => (
        <span className={`text-primary fs-7 fw-bold`} key={ind + 1 + ''}>
          {x.pickupPointName}
          <br />
        </span>
      ))



    case "plasStat":
      return user?.centreInfo?.name
    case "cuInfo":
      return user?.customerInfo?.name
    case "pickupAdd":
      return user?.pickupInfo?.name




    case 'pickup_address':
      return clipText(user.pickupInfo?.name + ',  ' + generateAddress(user.pickupInfo?.address))
    case 'img1':
    case 'img2':
      return user?.icons || user.images ? (
        <div className='align-items-center'>
          <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
            <div className='symbol-label'>
              <img
                alt={user.name}
                // className='w-100'
                src={`${(user?.icons || user?.images)?.[mapData === 'img1' ? 0 : 1]?.url || ''}`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='align-items-center'>
          <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
            <div className='symbol-label'>
              <img
                alt={user.name}
                // className='w-100'
                src={`${user?.icons?.[mapData === 'img1' ? 0 : 1]?.url || ''}`}
              />
            </div>
          </div>
        </div>
      )
    case 'address':
      // return generateAddress(user.address || user.pickupInfo?.address)

      const addressConcat = generateAddress(
        user.address || user.pickupInfo?.address || user.dropOffPointInfo?.address
      )
      return (
        <div data-bs-toggle='tooltip' title={addressConcat}>
          {clipText(addressConcat, 25)}
        </div>
      )
    case 'manager':
      return user.companyDetails.name
    case 'geoLocation':
      return user.address.latitute + ' , ' + user.address.longitute
    case 'pickupDate':
      return new Date(+user?.pickupInfo?.pickupDate || new Date()).toLocaleDateString()
    case 'badge':
      return (
        (user?.badge?.length &&
          user?.badge?.map((x) => (
            <span className=''>
              {x?.badgeName} <br />
            </span>
          ))) ||
        ''
      )
    case 'paymentMode':
      return (
        <span
          className={`badge badge-light-${user?.[mapData] === 'CASH'
            ? 'info'
            : user?.[mapData] === 'WALLET'
              ? 'primary'
              : 'success'
            } fs-7 fw-bold`}
        >
          {user?.[mapData] || user?.personalDetails?.[mapData]}
        </span>
      )
    case 'level':
      return (
        <span className='text-primary fs-7 fw-bold'>{user.level === 0 ? '0' : user.level}</span>
      )

    case 'createdAt':
      return new Date(+user?.createdAt || new Date()).toLocaleDateString()
    case 'status':
      const statusData = user?.[mapData] || user?.personalDetails?.[mapData]
      switch (statusData) {
        case 'quantityDeposit':
          return <span className='text-primary fs-7 fw-bold'>+28%</span>
        case 'Pickup Completed':
        case 'Completed':
          return <span className='badge badge-light-success fs-7 fw-bold'>{statusData}</span>
        case 'Accepted':
          return <span className='badge badge-light-danger fs-7 fw-bold'>{statusData}</span>
        case 'Pickup Assigned':
          return <span className='badge badge-light-primary fs-7 fw-bold'>{statusData}</span>

        default:
          return user?.[mapData] || user?.personalDetails?.[mapData]
      }
    default:
      return typeof user[mapData] === 'boolean' ? (
        user[mapData] ? (
          'Yes'
        ) : (
          'No'
        )
      ) : typeof user[mapData] === 'number' ? (
        user?.[mapData] === 0 ? (
          <span className='badge badge-light-danger fs-7 fw-bold'>
            {user?.[mapData]?.toFixed(2)}
          </span>
        ) : (
          user?.[mapData]?.toFixed(2) || user?.personalDetails?.[mapData]?.toFixed(2) || ''
        )
      ) : (
        user?.[mapData] || user?.personalDetails?.[mapData] || ''
      )
  }
}

const UserInfoCell: FC<Props> = ({
  user,
  showImageOnly = false,
  showImage = false,
  mapData = '',
}) => {
  return (
    <div className='align-items-center'>
      {showImageOnly && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={user.icon || toAbsoluteUrl(`/media/${user.avatar}`)}
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )}
      {showImage && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <a href='#'>
            {user.avatar ? (
              <div className='symbol-label'>
                <img
                  src={user.icon || toAbsoluteUrl(`/media/${user.avatar}`)}
                  alt={user.name}
                  className='w-100'
                />
              </div>
            ) : (
              <div
                className={clsx(
                  'symbol-label fs-3',
                  `bg-light-${user.initials?.state}`,
                  `text-${user.initials?.state}`
                )}
              >
                {user.initials?.label}
              </div>
            )}
          </a>
        </div>
      )}
      {!showImageOnly && (
        <div className='d-flex flex-column'>
          <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {returnData(user, mapData)}
          </a>
        </div>
      )}
    </div>
  )
}

function clipText(text, maxLength = 25, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
}

export { UserInfoCell }
